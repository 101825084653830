<template>
    <transition name="fade">
      <div class="card" style="width: 100%;" v-if="!state.loaderEnabled">
        <div class="card-header border-0 pt-6" style="display: flex; justify-content: space-between; align-items: stretch; flex-wrap: nowrap; min-height: 70px; padding: 0 2.25rem; background-color: transparent; flex-direction: row;">
          <el-divider content-position="left"><h2>Liste des utilisateurs de la société</h2></el-divider>
          <button class="btn btn-primary mx-2" style="width: 220px;" @click="addEdit_user({'us_seq': -1})">Ajouter un utilisateur</button>        
        </div>
        <div class="card-body pt-0">
            
          

          <Datatable
            v-if="state.myList && state.myList.length > 0"
            :table-data="state.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :rows-per-page="50"
            empty-table-text="Aucune ligne à afficher."
            :key="state.loadingDatatable"
            class="mt-4 table-small-td cursor-pointer"
            @selectRows="addEdit_user($event)"
          >

            <template v-slot:cell-con_prenom="{ row: data }">
              <div  class="fs-5 text-gray-700">{{ data.con_prenom }} {{ data.con_nom }}</div>
            </template>
            
            <template v-slot:cell-con_mail="{ row: data }">
              <div  class="fs-5 text-gray-900">{{ data.con_mail }}</div>
            </template>

            <template v-slot:cell-con_role_web="{ row: data }">
              <el-tag size="large" class="mx-1" effect="plain" >{{ data.con_role_web == "Administrateur" ? "Administrateur" : "Utilisateur" }}</el-tag>
            </template>

            <template v-slot:cell-con_seq="{ row: data }">
              <div v-if="data.con_x_acces_web == '1'" class="mx-2 text-gray-900 badge badge-light-success fs-7">
                Accès WEB
              </div>
              <div v-else class="mx-2 text-gray-900 badge badge-light-danger fs-7">
                Inactif
              </div>
            </template>

            

            <template v-slot:cell-listSsOrga="{ row: data }">
              <el-tag type="info" size="large" class="mx-1" effect="plain" v-for="item, index in data.listSsOrga" :key="index">
               {{ state.ssOrga.filter(el => el.ssm_seq == item)[0].ssm_sous_orga }}
              </el-tag>
            </template>
            
          </Datatable>


        </div>
      </div>
    </transition>

    <el-drawer direction='rtl' v-model="state.drawer" :with-header="false" size="800px" modal-class="modal-transp">
      <div class="card shadow-none" style="width: 100%; height: 100%;">
        <div class="card-header" id="kt_activities_header" style="background: white;">
          
          <div class="page-title d-flex  align-items-center  flex-wrap mb-5 mb-lg-0 " style="justify-content: space-between; width: 100%;">
            <button @click="state.drawer = false" class="btn btn-light"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
            <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
              {{ state.typeEddEdit }}
            </h1>
            <button @click="applyChange()" class="btn btn-primary">Appliquer<i class="fas fa-check fs-4 ms-2" ></i></button>
          </div>
        </div>
        <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background-size: cover;">


          <div class="card p-10" style="width: 100%;">

            <el-divider content-position="left"><h2>Informations principales</h2></el-divider>
            <br>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Prénom</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="text" class="mb-3 mb-lg-0" placeholder="Prénom" v-model="state.form.con_prenom" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Nom</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="text" class="mb-3 mb-lg-0" placeholder="Nom" v-model="state.form.con_nom" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Rôle utilisateur</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">

                    <el-select :disabled="state.form.con_seq == 1 ? true : false" v-model="state.form.con_role_web" default-first-option placeholder="Veuillez choisir un rôle" style='float: left; width: 100%'>
                        <el-option label="Utilisateur standard" value="Utilisateur" />
                        <el-option label="Administrateur" value="Administrateur" />
                    </el-select>

                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Accès Web</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-switch
                      v-model="state.form.con_x_acces_web"
                      size="large"
                      class="mt-3"
                      active-text="Actif"
                      inactive-text="Inactif"
                    />
                  </div>
                </div>
              </div>
            </div>

            <el-divider content-position="left"><h2>Paramètrage MERAKI</h2></el-divider>
            <br>

            <div class="row mb-6" v-loading="state.loaderEnabledTest">
              <label class="col-lg-3 col-form-label fw-bold fs-6">Clé API Meraki</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-8 fv-row">
                    <el-input type="password" class="mb-3 mb-lg-0" placeholder="Saisir votre clé API Meraki" v-model="state.form.con_meraki_key" />
                  </div>
                  <div class="col-lg-4 fv-row">
                    <button type="button" class="btn btn-light-primary w-100"  @click="test_api()">
                      <span class="indicator-label"> Tester la clé API </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6" v-if="state.ssOrga && state.ssOrga.length > 0">
              <label class="col-lg-3 col-form-label fw-bold fs-6">Sous Organisations</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    
                    <el-switch
                      v-model="state.form.con_x_restr_ssorga"
                      size="large"
                      class="mt-3 mb-3"
                      active-text="Accès qu'a certaines sous organisations"
                      inactive-text="Accès à tout"
                    />
                    <br>
                    <el-select v-if="state.form.con_x_restr_ssorga" v-model="state.form.lstssOrga" multiple placeholder="Selection de sous organisations" style='float: left; width: 100%'>
                      <el-option v-for="item, index in state.ssOrga" :key="index" :label="item.ssm_sous_orga" :value="item.ssm_seq"  />
                    </el-select>

                  </div>
                </div>
              </div>
            </div>

            <br>
            <el-divider content-position="left"><h2>Informations de connexion</h2></el-divider>
            <br>
            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Mail de connexion</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="text" class="mb-3 mb-lg-0" id="IdentConnexion" name="IdentConnexion" placeholder="Mail de connexion" v-model="state.form.con_mail" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label  fw-bold fs-6" :class="{ 'required':state.form.con_seq == -1 }">Nouveau Mot de passe</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-8 fv-row">
                    <el-input type="password" id="newPass" class="mb-3 mb-lg-0" placeholder="Saisir le nouveau Mot de passe" v-model="state.form.new_password" />
                  </div>
                  <div class="col-lg-4 fv-row">
                    <button type="button" class="btn btn-light-primary w-100"  @click="test_api()">
                      <span class="indicator-label"> Générer </span>
                    </button>
                  </div>

                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label fw-bold fs-6" :class="{ 'required':state.form.con_seq == -1 }">Répéter le mot de passe</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="password" id="renewPass"  class="mb-3 mb-lg-0" placeholder="Répéter le nouveau Mot de passe" v-model="state.form.new_repassword" />
                  </div>
                </div>
              </div>
            </div>
  

          </div>



        </div>
      </div>
    </el-drawer>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { ElNotification } from 'element-plus'

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [] as any,
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: "",
        form : {} as any,
        loaderEnabledTest : false,
        typeEddEdit : "",
        ssOrga: [] as any,


    });

    const tableHeader = ref([
      { name: "Prénom Nom", key: "con_prenom", sortable: false},
      { name: "Utilisateur", key: "con_mail", sortable: false,},
      { name: "Role", key: "con_role_web", sortable: false,},
      { name: "Statut", key: "con_seq", sortable: false},  
      { name: "Sous Organisation", key: "listSsOrga", sortable: false,},
    ]);

      onMounted(async () => {  
        setCurrentPageBreadcrumbs("Paramétrage des utlisateurs", []);

        loadDatas();

      });

      const loadDatas = async () => {
        let nUsers = await getAxios("/getUsers");
        
        state.myList = nUsers.results;
        state.initialMyList = state.myList;
        
        const ssOrga = await getAxios("/getSousOrgaMeraki");       
        state.ssOrga = ssOrga.results;

        state.myList.forEach(element => {
          element.listSsOrga = element.listSsOrga ? element.listSsOrga.split(',') : [];
        });

        state.loaderEnabled = false;
      }

      const addEdit_user = (data) => {
        console.log(data);
        if(data.con_seq == -1){
          state.typeEddEdit = "Ajout d'un utilisateur";

          state.form.con_seq = -1;
          state.form.con_prenom = "";
          state.form.con_nom = "";
          state.form.con_mail = "";
          state.form.con_meraki_key = "";
          state.form.con_role_web = "Utilisateur";
          state.form.new_password = "";
          state.form.new_repassword = "";
          state.form.con_x_acces_web = true;
          state.form.lstssOrga = [];
          state.form.con_x_restr_ssorga = 0;

        } else {
          state.typeEddEdit = "Modification d'un utilisateur";

          state.form.con_seq = data.con_seq;
          state.form.con_prenom = data.con_prenom;
          state.form.con_nom = data.con_nom;
          state.form.con_mail = data.con_mail;
          state.form.con_meraki_key = data.con_meraki_key;
          state.form.con_role_web = data.con_role_web == "Administrateur" ? "Administrateur" : "Utilisateur";
          state.form.new_password = "";
          state.form.new_repassword = "";
          
          state.form.con_x_acces_web = false;
          if(data.con_x_acces_web == '1') state.form.con_x_acces_web = true;

          state.form.con_x_restr_ssorga = false;
          if(data.con_x_restr_ssorga == '1') state.form.con_x_restr_ssorga = true;

          state.form.lstssOrga = data.listSsOrga;
          
        }
        state.drawer = true;
      };

      const test_api = async () => {
        if(!state.form.con_meraki_key || state.form.con_meraki_key=="") {
          ElNotification({
                title: 'Erreur',
                message: 'Veuillez saisir une clé',
                type: 'error',
              })
          return false;
        }
        mAxiosApi
          .get("testAPIMeraki/" + state.form.con_meraki_key)
          .then(({ data }) => {
            if(data.results.length > 0) {
              console.log(data);
              ElNotification({
                title: 'Succès',
                message: 'Votre clé semble valide',
                type: 'success',
              })
            }else {
              ElNotification({
                title: 'Erreur',
                message: 'Votre clé ne semble pas valide',
                type: 'error',
              }) 
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      const applyChange = async () => {

        if (state.form.con_seq == -1 && !state.form.new_password) {
          ElNotification({ title: 'Error', message: "Lors de la création d'un compte, le mot de passe doit être stipulé", type: 'error',})
          return false;
        }

        if (state.form.new_password && state.form.new_password != state.form.new_repassword) {
          ElNotification({ title: 'Error', message: "Les mots de passe saisis sont différents", type: 'error',})
          return false;
        }

        if (!state.form.con_mail || !state.form.con_prenom || !state.form.con_nom){
          console.log(state.form);
          ElNotification({ title: 'Error', message: 'Un des champs obligatoire n\'est pas saisi', type: 'error',})
          return false;
        } 

        const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!regexMail.test(state.form.con_mail)){
          ElNotification({ title: 'Error', message: 'Le mail de connexion doit être un mail valide', type: 'error',})
          return false;
        }

        const form = JSON.stringify(state.form);
        await mAxiosApi.post("/addEditUser", form, { headers: {   'Content-Type': 'application/json'  }  });
        ElNotification({ title: 'Succés', message: 'Changements effectués', type: 'success',})
        
        await loadDatas();
        state.loadingDatatable = state.loadingDatatable + 1;
        state.drawer = false;

      }
  
      return {
        state,
        store,
        tableHeader,
        addEdit_user,
        test_api,
        applyChange
      };
    },
  });
  
  export const getAxios = async (rr) => { const re = await mAxiosApi.get(rr); return re.data; };

  </script>